<template>
  <div>
    <div class="div">213123</div>
    <el-button type="success" @click="bindkey">点击</el-button>
  </div>
</template>
<script>
import { getLodop } from '../../../../plugins/LodopFuncs';
export default {
  data() {
    return {
      num: 1
    };
  },
  methods: {
    bindkey() {
      const LODOP = getLodop();
      console.log('lodop', LODOP);
      LODOP.PRINT_INIT('');
      LODOP.SET_PRINT_PAGESIZE(1, '50mm', '80mm', '');
      LODOP.SET_PRINT_STYLE('Alignment', 2);
      // LODOP.ADD_PRINT_HTM(10,5,400,400,"<hr><font size=5>"+this.num +"</font>")
      LODOP.ADD_PRINT_IMAGE(
        50,
        50,
        400,
        400,
        "<img width='140mm' height='140mm' src='http://192.168.1.38:8885/file/QRCode/20211029/1635487933225.jpg?1635752334001'/>"
      );
      LODOP.PREVIEW();
    }
  }
};
</script>
<style scoped>
.div {
  width: 100px;
  height: 100px;
}
</style>
